<template>
  <div class="viewPushData">
    <div class="header-query">
      <el-button type="info"
                 @click="downOtherAward">下 载</el-button>
      <div>
        <el-select placeholder="请选择状态"
                   class="query-select"
                   v-model="queryStutus">
          <el-option v-for="status in statusList"
                     :key="status.value"
                     :label="status.label"
                     :value="status.value"></el-option>
        </el-select>
        <el-button type="primary">查 询</el-button>
      </div>
    </div>
    <el-table :data="tableData"
              v-if="featuredEventDetailsTable.length===0">
      <el-table-column label="研讨主题"
                       key="2"
                       v-if="type===1"
                       align="center"
                       prop="title">
      </el-table-column>
      <el-table-column label="活动名称"
                       v-if="type===2"
                       key="3"
                       align="center"
                       prop="name">
      </el-table-column>
      <el-table-column label="活动主题"
                       key="4"
                       v-if="type===3"
                       align="center"
                       prop="theme">
      </el-table-column>
      <el-table-column label="班级"
                       key="5"
                       align="center"
                       prop="cname">
      </el-table-column>
      <el-table-column label="班主任"
                       key="6"
                       align="center"
                       prop="headmaster">
      </el-table-column>

      <el-table-column label="主持人"
                       key="7"
                       v-if="type===1"
                       align="center"
                       prop="host">
      </el-table-column>
      <el-table-column label="研讨时间"
                       key="8"
                       v-if="type===1"
                       align="center"
                       prop="activity_time">
      </el-table-column>
      <el-table-column label="活动地点"
                       key="13"
                       v-if="type===3"
                       align="center"
                       prop="address">
      </el-table-column>
      <el-table-column label="活动时间"
                       key="10"
                       v-if="type!==1"
                       align="center"
                       prop="activity_time">
      </el-table-column>
      <el-table-column label="状态"
                       key="11"
                       align="center"
                       prop="statusStr">
      </el-table-column>
      <el-table-column label="操作"
                       key="12"
                       width="100px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="viewDetail(scope.row)">详 情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 查看班级特色活动主题登记的详情 -->
    <el-table :data="featuredEventDetailsTable"
              v-if="featuredEventDetailsTable.length!==0">
      <el-table-column label="考核人"
                       key="14"
                       align="center"
                       prop="check_name">
      </el-table-column>
      <el-table-column label="状态"
                       key="15"
                       align="center"
                       prop="statusStr">
      </el-table-column>
      <el-table-column label="操作"
                       key="16"
                       width="100px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="viewfeaturedEventDetails(scope.row)">查 看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="detailVisible"
               width="600px"
               title="查看">
      <el-form label-width="120px"
               v-if="type===1"
               :model="detailForm">
        <el-form-item label="研讨主题">
          <el-input v-model="detailForm.title"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="主持人">
          <el-input v-model="detailForm.host"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="研讨时间">
          <el-input v-model="detailForm.time"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="参与教师">
          <el-input v-model="detailForm.teacher"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="研讨记录过程">
          <el-input v-model="detailForm.content"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="考核小组参与人">
          <el-input v-model="detailForm.assessor"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="已考核人员">
          <el-input v-model="detailForm.signer"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
      </el-form>
      <el-form v-if="type===2"
               class="dialog-form"
               :model="detailForm"
               label-width="120px">
        <el-form-item label="活动名称">
          <el-input v-model="detailForm.name"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="班级">
          <el-input v-model="detailForm.cls"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-input v-model="detailForm.time"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="参与教师">
          <el-input v-model="detailForm.teacher"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="考核小组参与人">
          <el-input v-model="detailForm.assessor"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动目的">
          <el-input v-model="detailForm.tager"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动准备">
          <el-input v-model="detailForm.prepare"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动简要过程">
          <el-input v-model="detailForm.process"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="已考核人员">
          <el-input v-model="detailForm.signer"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <hr>
        <el-form-item label="考核人">
          <el-input v-model="detailForm.assessor"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动效果">
          <el-input v-model="detailForm.activityEffect"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动是否有特色">
          <el-input v-model="detailForm.activityFeatures"
                    disabled></el-input>
        </el-form-item>
      </el-form>
      <el-form v-if="type===3"
               class="dialog-form"
               :model="detailForm"
               label-width="120px">
        <el-form-item label="活动主题">
          <el-input v-model="detailForm.title"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="单元">
          <el-input v-model="detailForm.unit"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="班主任">
          <el-input v-model="detailForm.headmaster"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="班级">
          <el-input v-model="detailForm.cls"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-input v-model="detailForm.time"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动地点">
          <el-input v-model="detailForm.place"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="活动目标">
          <el-input v-model="detailForm.tager"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="参与教师">
          <el-input v-model="detailForm.teacher"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="考核小组">
          <el-input v-model="detailForm.assessor"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="已考核人员">
          <el-input v-model="detailForm.signer"
                    type="textarea"
                    autosize
                    disabled></el-input>
        </el-form-item>
        <hr>
        <div class="div-technological-process">流程</div>
        <div v-for="technologicalProcess in detailForm.technologicalProcessList"
             :key="technologicalProcess.id">
          <hr>
          <el-form-item label="时间">
            <el-input v-model="technologicalProcess.flow_time"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="项目">
            <el-input v-model="technologicalProcess.name"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="内容及要求">
            <el-input v-model="technologicalProcess.content"
                      type="textarea"
                      autosize
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="负责人">
            <el-input v-model="technologicalProcess.duty"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="地点">
            <el-input v-model="technologicalProcess.address"
                      disabled></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer">
        <el-button type="primary"
                   @click="detailVisible=false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "viewPushData",
  data() {
    return {
      tableData: [],
      type: this.$route.query.type,
      culturalId: this.$route.query.id,
      queryStutus: "",
      statusList: [
        {
          value: 3,
          label: "全部",
        },
        {
          value: 2,
          label: "已完成",
        },
        {
          value: 1,
          label: "未完成",
        },
        {
          value: 0,
          label: "进行中",
        },
      ],
      detailVisible: false,
      detailForm: {},
      featuredEventDetailsTable: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    to.meta.name = `${to.query.name} 详情`;
    next();
  },
  created() {
    this.loadPushDataDetail();
  },
  methods: {
    loadPushDataDetail() {
      this.$post("/base/queryClassCulturalDetail.do", {
        type: this.type,
        cultural_id: this.culturalId,
      })
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          this.$message({ type: "warning", message: err.message });
        });
    },
    viewDetail(row) {
      if (this.type === 1) {
        this.detailVisible = true;
        this.detailForm.title = row.title;
        this.detailForm.host = row.host;
        this.detailForm.time = row.discuss_time;
        this.detailForm.teacher = row.mnames1;
        this.detailForm.content = row.content;
        this.detailForm.assessor = row.mnames;
        this.detailForm.signer = row.signer;
      }
      if (this.type === 2) {
        //   type=1是固定的管理员查看
        this.$post("/base/queryClassSpecialCheckDetail.do", {
          id: row.id,
          type: 1,
        })
          .then((res) => {
            this.featuredEventDetailsTable = res.data;
            this.detailForm.name = row.name;
            this.detailForm.cls = row.cname;
            this.detailForm.time = row.activity_time;
            this.detailForm.teacher = row.mnames1;
            this.detailForm.assessor = row.mnames; //考核小组参与人
            this.detailForm.tager = row.objective;
            this.detailForm.prepare = row.ready; //活动准备
            this.detailForm.process = row.content; //活动简要过程
          })
          .catch((err) => {
            this.$message({ type: "warning", message: err.message });
          });
      }
      if (this.type === 3) {
        this.detailVisible = true;
        this.detailForm.title = row.theme;
        this.detailForm.unit = row.unit;
        this.detailForm.headmaster = row.headmaster;
        this.detailForm.cls = row.cname;
        this.detailForm.time = row.activity_time;
        this.detailForm.place = row.address;
        this.detailForm.tager = row.target;
        this.detailForm.teacher = row.mnames1;
        this.detailForm.assessor = row.mnames;
        this.detailForm.technologicalProcessList = row.list;
      }
    },
    viewfeaturedEventDetails(row) {
      this.detailVisible = true;
      this.detailForm.assessor = row.check_name;
      this.detailForm.activityEffect = row.levelStr; //活动效果
      this.detailForm.activityFeatures = row.is_typeStr; //活动是否有特色
    },
    downOtherAward() {
      const that = this;
      let paramas = new FormData();
      paramas.append("type", this.type);
      paramas.append("id", this.culturalId);
      this.$postFile("/base/exportClassWord.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/msword",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.header-query {
  display: flex;
  justify-content: space-between;
}
.query-select {
  margin: 0 10px;
}
.dialog-form {
  height: 500px;
  overflow-x: auto;
}
.div-technological-process {
  text-align: center;
}
</style>
